<template>
  <section class="contact-area pt-120 pb-80 fix">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 col-xl-6 col-lg-6">
          <div class="section-title-wrapper mb-15">
            <h5 class="section-subtitle mb-20">contact with us</h5>
            <h2 class="section-title">Speak to us</h2>
          </div>
        </div>
        <div class="col-xxl-7 col-xl-6 col-lg-6">
          <div class="contact-form">
            <form ref="form" @submit.prevent="sendEmail">
              <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                  <input v-model="name" type="text" placeholder="Your Name" />
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                  <input
                    v-model="email"
                    type="email"
                    placeholder="Email Adress"
                  />
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                  <input v-model="phone" type="text" placeholder="Phone" />
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                  <input v-model="subject" type="text" placeholder="Subject" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 mb-20">
                  <textarea
                    placeholder="Write Massage"
                    v-model="message"
                  ></textarea>
                </div>
                <div class="col-xxl-12 col-xl-12 mb-20">
                  <button type="submit" class="tp-btn">Send a message</button>
                </div>
              </div>
            </form>
            <vue-basic-alert :duration="300" :closeIn="9000" ref="alert" />
            <p class="ajax-response"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from "emailjs-com";
import VueBasicAlert from "vue-basic-alert";
export default {
  name: "ContactArea",
  components: {
    VueBasicAlert,
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    };
  },
  methods: {
    sendEmail() {
      try {
        console.log(
          "message:",
          this.name,
          this.email,
          this.phone,
          this.subject,
          this.message
        );
        emailjs.send(
          "service_gbcj1jq",
          "template_a8ebztq",
          {
            name: this.name,
            email: this.email,
            phone: this.phone,
            subject: this.subject,
            message: this.message,
          },
          "GTohVZF5GWcjq9V-l"
        );
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
      this.phone = "";
      this.subject = "";
      this.$refs.alert.showAlert(
        "success", // There are 4 types of alert: success, info, warning, error
        "Thanks for your message", // Header of the alert
        " We will contact you as soon as possible" // Message of the alert
      );
    },
  },
};
</script>