<template>
  <section class="project-details-area pt-120 pb-70">
    <div class="container">
      <!--<div class="row">
        <div class="col-xxl-12">
          <div class="project-big-thumb">
            <img
              src="../../assets/img/portfolio/portfolio-big-img.jpg"
              alt=""
            />
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12">
          <div class="p-details-content mb-40">
            <h3>Unlimited eForm Capacities</h3>
            <p>
              Beside the out of the box usual inputs like text fields, date/time
              fields and the less obvious ones like to capture photos, an XClin
              eForm can include any specific components like:
            </p>
            <li>• An interface to capture sensor data</li>
            <li>• An interface to capture EHR data</li>
            <li>• A Covid Certificate checker</li>
            <li>• A training video</li>
            <li>• A video conference tool</li>
            <li>• Etc</li>
            <p></p>
            <p>
              There is no limit to the possibilities, feel free to ask our
              engineers
            </p>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
          <div class="platform-big-thumb">
            <img src="../../assets/img/platform/Sensor.png" alt="" />
          </div>
          <!--<div class="sidebar-wrap mb-40">
                           <div class="sidebar-right">
                              <div class="sidebar-single">
                                    <label>Clients:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Category:</label>
                                    <span>Design, Development</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Value:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Date:</label>
                                    <span>20 June, 2022</span>
                              </div>
                           </div>
                        </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PlatformDetailsSensors",
};
</script>