<template>
  <Header />
  <!-- <Breadcrumb title="Platform Details" subtitle="" />-->
  <PharmaElevatorPitchDetail />
  <!--<PaginationArea :detailIndex="detailIndex" />-->
  <!--<FeaturesArea />-->
  <Footer />
</template>

<script>
import Header from "../../components/Home/Header.vue";
//import Breadcrumb from "../../components/common/Breadcrumb.vue";
import PharmaElevatorPitchDetail from "../../components/ElevatorPitch/PharmaElevatorPitchDetail.vue";

//import PaginationArea from "../../components/PlatformDetails/PaginationArea.vue";
//import FeaturesArea from "../../components/PlatformDetails/FeaturesArea.vue";
import Footer from "../../components/Home/Footer.vue";

export default {
  name: "PlatformDetails",
  components: {
    Header,
    //  Breadcrumb,
    PharmaElevatorPitchDetail,
    //PaginationArea,
    //  FeaturesArea,
    Footer,
  },
};
</script>