<template>
  <section class="project-details-area pt-120 pb-70">
    <div class="container">
      <!--<div class="row">
        <div class="col-xxl-12">
          <div class="project-big-thumb">
            <img
              src="../../assets/img/portfolio/portfolio-big-img.jpg"
              alt=""
            />
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12">
          <div class="p-details-content mb-40">
            <h3>Close Collaboration with the PICAPS foundation</h3>
            <p>
              As first contributor to the PICAPS foundation (Patient In Control,
              Anonymity Secured), NovaXon is best positioned to get the most
              from the many possibilities it will offer.
            </p>
            <p>
              The PICAPS system allows to stay in direct contact with the
              patients without knowing its real identity. Any communication goes
              through patient trusted organizations independent from the pharma
              sponsors, NovaXon and the PICAPS foundation itself.
            </p>
            <p>Such collaboration will enable many innovative features like:</p>
            <li>
              • Display the real name of the patient to authorized personnels
              like study nurses in the XClin system without the need to have
              such name saved in the XClin system
            </li>
            <li>• Complement Real World Data with patient collected data</li>
            <li>
              • Protect the anonymity of the patients while using new Digital
              Health Technologies
            </li>
            <li>• Support Personalized Medicine Manufacturing</li>
            <li>• More to Follow</li>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
          <div class="platform-big-thumb">
            <img src="../../assets/img/platform/AccessGranted.png" alt="" />
          </div>
          <!--<div class="sidebar-wrap mb-40">
                           <div class="sidebar-right">
                              <div class="sidebar-single">
                                    <label>Clients:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Category:</label>
                                    <span>Design, Development</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Value:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Date:</label>
                                    <span>20 June, 2022</span>
                              </div>
                           </div>
                        </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PlatformDetailsPICAPS",
};
</script>