<template>
  <section
    class="
      review-area review-area-padding
      pos-rel
      pl-50
      pr-50
      grey-bg
      pt-120
      pb-90
    "
  >
    <div class="container" id="patientstory">
      <div class="row mb-40 z-index">
        <div class="col-12">
          <div class="sec-wrapper text-center">
            <h5>Pharmaceutical Industry</h5>
            <h2 class="section-title">Elevator Pitch</h2>
          </div>
        </div>
      </div>
      <div class="test-active swiper-container">
        <!--<video
          id="my-video"
          controls
          preload="auto"
          width="100%"
          height="70%"
          data-setup="{}"
        >
          <source src="/video/PharmaElevatorPitch.mp4" type="video/mp4" />
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading
            to a web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank"
              >supports HTML5 video</a
            >
          </p>
        </video>
        <video-player
          src="/video/PharmaElevatorPitch.mp4"
          poster="/your-path/poster.jpg"
          controls
          :loop="false"
          :volume="0.8"
        />
        <vue3videoPlay
          width="800"
          src="/video/PharmaElevatorPitch.mp4"
          control="true"
          controlBtns="volume,pageFullScreen,fullScreen"
        />-->
        <vue3-video-player
          preload="auto"
          src="/video/PharmaElevatorPitch.mp4"
          cover="/video/ElevatorPitch.png"
        ></vue3-video-player>

        <!--<video
          width="100%"
          height="70%"
          controls
          ref="videoPlayer"
          class="video-js"
        ></video>-->

        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
</template>

<script>
//import { VideoPlayer } from "@videojs-player/vue";
//import "video.js/dist/video-js.css";
import "vue3-video-play/dist/style.css";
//import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";

export default {
  name: "ElevatorPitchDetail",
  /*components: {
    Vue3VideoPlayer,
  },*/

  data() {
    return {
      player: null,
    };
  },
};
</script>
<style >
/* to rebrand red start button */
svg path {
  fill: #4adede;
}
svg rect {
  fill: #4adede;
}
svg {
  fill: #4adede;
}
</style>