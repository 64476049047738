<template>
  <section class="project-details-area pt-120 pb-70">
    <div class="container">
      <!--<div class="row">
        <div class="col-xxl-12">
          <div class="project-big-thumb">
            <img
              src="../../assets/img/portfolio/portfolio-big-img.jpg"
              alt=""
            />
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12">
          <div class="p-details-content mb-40">
            <h3>Simple, User Friendly eForms</h3>
            <p>eForms can be customized to fit any clinical process.</p>
            <p>
              While most alternative solutions position the data entry elements
              in a list, XClin allows the positioning in two dimensions as it
              would be done on a paper form: this allows the building of eForm
              much more intuitive and pleasant to work with.
            </p>
            <p>
              Close to each data elements, a status indicator guides the users
              to facilitate the collection of quality data
            </p>
            <li>• Green: everything is fine</li>
            <li>• Blue: data incomplete</li>
            <li>• Red: Data most likely erroneous</li>
            <li>
              • But also data reviewed, source data verified, frozen, commented,
              not available …
            </li>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
          <div class="platform-big-thumb">
            <img src="../../assets/img/platform/XClinScreenShot.png" alt="" />
          </div>
          <!--<div class="sidebar-wrap mb-40">
                           <div class="sidebar-right">
                              <div class="sidebar-single">
                                    <label>Clients:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Category:</label>
                                    <span>Design, Development</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Value:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Date:</label>
                                    <span>20 June, 2022</span>
                              </div>
                           </div>
                        </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PlatformDetailsGUI",
};
</script>