<template>
  <section
    class="review-area review-area-padding pos-rel pl-50 pr-50 grey-bg pt-120 pb-90"
  >
    <div class="container" id="optout">
      <div class="row mb-40 z-index">
        <div class="col-12">
          <div class="sec-wrapper text-center">
            <h5>European Health Data Space (EHDS)</h5>
            <h2 class="section-title">Managing Opt Out and more</h2>
          </div>
        </div>
      </div>

      <p class="c4">
        <span
          >In May 2022, the European Commission proposed a new legislation
          called </span
        ><span class="c16"
          ><a
            class="c15"
            href="https://health.ec.europa.eu/ehealth-digital-health-and-care/european-health-data-space_en"
            >European Health Data Space</a
          ></span
        ><span class="c12"></span>&nbsp;which has for second objective to
        provide a consistent, trustworthy and efficient set-up for the use of
        health data for research, innovation, policy-making and regulatory
        activities (secondary use of data)
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        Many in the industry put a lot of hope in this new legislation, which
        should facilitate EU data access and make it comparable to the situation
        in the US. The legislation should put the EU back in the game when it
        comes to new medical services based on health data and AI.
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        The outcome of the legislative process (EU Parliament and EU Council) in
        the upcoming months is unknown, but some amendments are possible, like
        the possibility for patients to opt-out of the secondary use of their
        health records or even the requirement of
        <span class="c16"
          ><a
            class="c15"
            href="https://www.google.com/url?q=https://open.overheid.nl/documenten/43c52d68-3078-4fb3-a602-669775e71a9e/file&amp;sa=D&amp;source=editors&amp;ust=1687179853123597&amp;usg=AOvVaw3LaUNzZhtmaj1q8U-4Ups3"
            >explicit consent when dealing with genomic data</a
          ></span
        ><span>&nbsp;(opt-in)</span><span class="c4">.</span>
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        <span>Recently, 32 healthcare organizations, in a</span
        ><span class="c16"
          ><a
            class="c15"
            href="https://www.google.com/url?q=https://www.efpia.eu/media/h3qd3u34/opt-out-statement-multistakeholders.pdf&amp;sa=D&amp;source=editors&amp;ust=1687179853123998&amp;usg=AOvVaw1dbPjegy8JA_BdslUjJ9y-"
            >&nbsp;joint statement,
          </a></span
        >
        defined the requirements for a possible opt-out while highlighting the
        risk of the introduction of bias.
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        Let&rsquo;s take the challenge and propose an approach with the
        following objectives:
      </p>
      <ul class="c4 lst-kix_lvvaij1on1qb-0 start">
        <li class="c4 c1 li-bullet-0">
          ensure sufficient transparency so that citizens are well informed of
          the opt-out
        </li>
        <li class="c4 c1 li-bullet-0">
          do not overload healthcare professionals and other data holders.
        </li>
        <li class="c4 c1 li-bullet-0">
          enable the measurement of the bias introduced by the opt-out (or
          opt-in) and give the possibility to correct it
        </li>
        <li class="c4 c1 li-bullet-0">
          preserve the data utility of the full original dataset
        </li>
        <li class="c4 c1 li-bullet-0">
          minimize the potential impact of differences in the EHDS national
          implementations
        </li>
        <li class="c4 c1 li-bullet-0">
          Increase patient and citizen trust in such type of research
        </li>
      </ul>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        The proposed approach is the result of the combination of three
        privacy-respecting approaches that, taken independently, do not satisfy
        many but, taken together, could help us achieve the above objectives.
      </p>
      <p class="c2"><span class="c3"></span></p>
      <ol class="c7 lst-kix_qgko7dyni29b-0 start" start="1">
        <li class="c4 c1 li-bullet-0">
          <span
            style="
              overflow: hidden;
              display: inline-block;
              margin: 0px 0px;
              border: 0px solid #000000;
              transform: rotate(0rad) translateZ(0px);
              -webkit-transform: rotate(0rad) translateZ(0px);
              width: 41.16px;
              height: 41.16px;
            "
            ><img
              alt=""
              src="./images/image1.png"
              style="
                width: 41.16px;
                height: 41.16px;
                margin-left: 0px;
                margin-top: 0px;
                transform: rotate(0rad) translateZ(0px);
                -webkit-transform: rotate(0rad) translateZ(0px);
              "
              title="" /></span
          ><span class="c4">&nbsp;Federated Aggregated Querying </span>
        </li>
      </ol>
      <p class="c2 c8"><span class="c3"></span></p>
      <a id="t.28033ac9318bc93883cc06fd0d79136eb9e516a0"></a><a id="t.0"></a>
      <table class="c9">
        <tr>
          <td class="c5" colspan="1" rowspan="1">
            <p class="c0"><span class="c4">What</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <ul class="c7 lst-kix_yxt01h54702q-0 start">
              <li class="c4 c1 li-bullet-0">The data stays at the hospitals</li>
              <li class="c4 c1 li-bullet-0">
                Researcher creates a query which is run against data at the
                hospitals
              </li>
              <li class="c4 c1 li-bullet-0">
                Only an aggregated result is returned
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c13">
          <td class="c5" colspan="1" rowspan="1">
            <p class="c0"><span class="c4">Pros</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <ul class="c7 lst-kix_j9qq9gseka2-0 start">
              <li class="c4 c1 li-bullet-0">
                There is no chance to re-identify the patients
              </li>
              <li class="c4 c1 li-bullet-0">
                There is no need of expert judgment to assess the
                re-identification risk
              </li>
              <li class="c4 c1 li-bullet-0">
                The data can be queries almost real time
              </li>
              <li class="c4 c1 li-bullet-0">
                Solutions exist with 250+ Millions patients worldwide &nbsp;
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c13">
          <td class="c5" colspan="1" rowspan="1">
            <p class="c0"><span class="c4">Cons</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <ul class="c7 lst-kix_ew1m0ki6ciuh-0 start">
              <li class="c4 c1 li-bullet-0">
                Aggregated patient data does not have the same statistical power
                as patient level data. It is a very blurry view on the data
              </li>
              <li class="c4 c1 li-bullet-0">
                There is no possibility to link data between datasets
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <p class="c2 c14"><span class="c3"></span></p>
      <ol class="c7 lst-kix_qgko7dyni29b-0" start="2">
        <li class="c4 c1 li-bullet-0">
          <span
            style="
              overflow: hidden;
              display: inline-block;
              margin: 0px 0px;
              border: 0px solid #000000;
              transform: rotate(0rad) translateZ(0px);
              -webkit-transform: rotate(0rad) translateZ(0px);
              width: 29.5px;
              height: 29.5px;
            "
            ><img
              alt=""
              src="./images/image4.png"
              style="
                width: 29.5px;
                height: 29.5px;
                margin-left: 0px;
                margin-top: 0px;
                transform: rotate(0rad) translateZ(0px);
                -webkit-transform: rotate(0rad) translateZ(0px);
              "
              title="" /></span
          ><span class="c4">&nbsp;Consented Patient Data </span>
        </li>
      </ol>
      <p class="c2 c8"><span class="c3"></span></p>
      <a id="t.5796d02f808d7b6bdff0c2db3cc121a70595298c"></a><a id="t.1"></a>
      <table class="c9">
        <tr class="c13">
          <td class="c5" colspan="1" rowspan="1">
            <p class="c0"><span class="c4">What</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p>
              <span class="c4"
                >Patients consent to the research knowing the risk/benefit
                profile (general or project specific consent).</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >Opt-in: data is shared only if the patient responds positively
              </span>
            </p>
            <p class="c0">
              <span class="c4"
                >Opt-Out: data is shared unless the patient is opposed to it
                &nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr class="c13">
          <td class="c5" colspan="1" rowspan="1">
            <p class="c0"><span class="c4">Pros</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <ul class="c7 lst-kix_lg09ab1ht2u7-0 start">
              <li class="c4 c1 li-bullet-0">
                It is the most ethical and privacy respectful approach to data
                sharing
              </li>
              <li class="c4 c1 li-bullet-0">
                Since most dataset can never be considered 100% anonymous, the
                remaining potential risk of the research is shared with the
                patients in full transparency
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c13">
          <td class="c5" colspan="1" rowspan="1">
            <p class="c0"><span class="c4">Cons</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <ul class="c7 lst-kix_gv030h50s9au-0 start">
              <li class="c4 c1 li-bullet-0">
                Existence of a selection bias in the patient group
              </li>
              <li class="c4 c1 li-bullet-0">
                Expected reduction of the sample size
              </li>
              <li class="c4 c1 li-bullet-0">
                Possible logistical difficulties if the right tool is not used
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <p class="c2 c14"><span class="c3"></span></p>
      <ol class="c7 lst-kix_qgko7dyni29b-0" start="3">
        <li class="c4 c1 li-bullet-0">
          <span
            style="
              overflow: hidden;
              display: inline-block;
              margin: 0px 0px;
              border: 0px solid #000000;
              transform: rotate(0rad) translateZ(0px);
              -webkit-transform: rotate(0rad) translateZ(0px);
              width: 28.5px;
              height: 28.5px;
            "
            ><img
              alt=""
              src="./images/image2.png"
              style="
                width: 28.5px;
                height: 28.5px;
                margin-left: 0px;
                margin-top: 0px;
                transform: rotate(0rad) translateZ(0px);
                -webkit-transform: rotate(0rad) translateZ(0px);
              "
              title="" /></span
          ><span class="c4">&nbsp;Synthetic Data </span>
        </li>
      </ol>
      <p class="c2 c8"><span class="c3"></span></p>
      <a id="t.2e1dc600ef46ec53207dd09505bb7bd41ac86dda"></a><a id="t.2"></a>
      <table class="c9">
        <tr class="c13">
          <td class="c5" colspan="1" rowspan="1">
            <p class="c0"><span class="c4">What</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c0">
              <span class="c4"
                ><a
                  class="c4"
                  href="https://www.google.com/url?q=https://edps.europa.eu/press-publications/publications/techsonar/synthetic-data_en&amp;sa=D&amp;source=editors&amp;ust=1687179853132201&amp;usg=AOvVaw0SPGBgOrVTg_HaZPUMCZxI"
                  >Synthetic data</a
                ></span
              ><span class="c4"
                >&nbsp;is artificial data that is generated from original data
                and a model that is trained to reproduce the characteristics and
                structure of the original data</span
              >
            </p>
          </td>
        </tr>
        <tr class="c13">
          <td class="c5" colspan="1" rowspan="1">
            <p class="c0"><span class="c4">Pros</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <ul class="c7 lst-kix_lg09ab1ht2u7-0">
              <li class="c4 c1 li-bullet-0">
                It is no longer considered personal data and most privacy
                legislation are no longer applicable
              </li>
              <li class="c4 c1 li-bullet-0">
                The resulting dataset has the same statistical power as the
                original dataset and expected to give the same statistical
                result
              </li>
              <li class="c4 c1 li-bullet-0">
                To some extent, it is possible to correct a selection biais
                introduced by a opt-out or opt-in option
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c13">
          <td class="c5" colspan="1" rowspan="1">
            <p class="c0"><span class="c4">Cons</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <ul class="c7 lst-kix_gv030h50s9au-0">
              <li class="c4 c1 li-bullet-0">
                It is difficult to trust the results of the research as the
                synthesization process might have altered the results.
              </li>
              <li class="c4 c1 li-bullet-0">
                There is a difficult balance between strong synthesization,
                which will change the research results and weak synthesization,
                which will not protect efficiency the privacy of patients
              </li>
            </ul>
            <p class="c0 c11"><span class="c3"></span></p>
          </td>
        </tr>
      </table>
      <p class="c2 c14"><span class="c3"></span></p>
      <p class="c4">
        While such approaches might individually be the solution for some
        specific use cases, their weaknesses do not encourage their widespread
        usage. That might be different if taken collectively according to the
        following process: &nbsp;
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        <span
          style="
            overflow: hidden;
            display: inline-block;
            margin: 0px 0px;
            border: 0px solid #000000;
            transform: rotate(0rad) translateZ(0px);
            -webkit-transform: rotate(0rad) translateZ(0px);
            width: 624px;
            height: 873.33px;
          "
          ><img
            alt=""
            src="./images/image3.png"
            style="
              width: 624px;
              height: 873.33px;
              margin-left: 0px;
              margin-top: 0px;
              transform: rotate(0rad) translateZ(0px);
              -webkit-transform: rotate(0rad) translateZ(0px);
            "
            title=""
        /></span>
      </p>
      <p class="c4">
        With such approaches, data analysts should not have to wait for months
        or years before realizing that the dataset is not what they expected.
        Some early data exploration should also enable a much more precise
        project definition to be submitted to the different authorities
        evaluating the research proposal, including for the acquisition of an
        EHDS data permit.
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        With this approach, we introduce the concept of a project specific
        Opt-Out. The patient&#39;s refusal to share its data for a particular
        research project does not imply the same refusal for other research
        projects, unless the patient chooses not to be asked again. The solution
        offers the possibility of informing the non-consenting patients of the
        conclusion of the research, which might increase their trust and
        motivate them to share their data for another research project. &nbsp;
        This kind of transparency should also avoid some possible negative press
        leading to massive opt-out registration, as
        <span class="c16"
          ><a
            class="c15"
            href="https://www.google.com/url?q=https://www.theguardian.com/society/2021/aug/22/nhs-data-grab-on-hold-as-millions-opt-out&amp;sa=D&amp;source=editors&amp;ust=1687179853135095&amp;usg=AOvVaw38Zl3DeIKSIQWsVicx4WkD"
            >happened in the UK last year</a
          ></span
        ><span class="c3">.</span>
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        The handling of opting out or in via a system like the one proposed by
        <span class="c16"
          ><a
            class="c15"
            href="https://www.google.com/url?q=https://picaps.org/&amp;sa=D&amp;source=editors&amp;ust=1687179853135398&amp;usg=AOvVaw1lO8df7uwS1F40vt81hmTB"
            >PicAps</a
          ></span
        >&nbsp;should be as simple as sending an email. To further limit any
        potential additional work for the healthcare workforce, the task of
        informing patients electronically or by letter could be contracted out.
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        Hopefully, the national HDABs will have the possibility of approving the
        communication materials sent to the patients, and a specific ethical
        committee&#39;s approval will not be required.
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        The use of synthetic data would ensure that the full statistical power
        of the original data set is preserved and could potentially correct any
        potential bias due to the opt-in or opt-out introduction.
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        The proposed framework is, to a great extent, compatible with existing
        EU legislation. The new EHDS will further improve it, but the framework
        does not depend on a particular implementation. It should thus be
        particularly resilient to possible national implementations.
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        Furthermore, the proposed framework is mostly based on existing
        infrastructure, and minimal investment should be needed both for a pilot
        and a subsequent significant scale-up.
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        With the above approach, the EU might then be able to provide the most
        efficient way to access health records worldwide while maintaining its
        image as best-in-class in terms of protecting patient privacy.
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">
        This remains to be confirmed and demonstrated, and we are looking
        forward to stress-testing the idea with &nbsp; concrete use cases.
        Please do not hesitate to contact us for more information
        (info@picaps.org).
      </p>
      <p class="c2"><span class="c3"></span></p>
      <p class="c4">The PicAps Association (20.6.2023)</p>
      <p class="c2"><span class="c3"></span></p>
    </div>
  </section>
</template>

<script>
//import { VideoPlayer } from "@videojs-player/vue";
//import "video.js/dist/video-js.css";
import "vue3-video-play/dist/style.css";
//import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";

export default {
  name: "OptOutDetail",
  /*components: {
    Vue3VideoPlayer,
  },*/

  data() {
    return {
      player: null,
    };
  },
};
</script>
<style type="text/css">
ul.lst-kix_ew1m0ki6ciuh-1 {
  list-style-type: none;
}
ul.lst-kix_ew1m0ki6ciuh-0 {
  list-style-type: none;
}
ul.lst-kix_ew1m0ki6ciuh-5 {
  list-style-type: none;
}
ul.lst-kix_ew1m0ki6ciuh-4 {
  list-style-type: none;
}
ul.lst-kix_ew1m0ki6ciuh-3 {
  list-style-type: none;
}
ul.lst-kix_ew1m0ki6ciuh-2 {
  list-style-type: none;
}
ul.lst-kix_ew1m0ki6ciuh-8 {
  list-style-type: none;
}
ul.lst-kix_ew1m0ki6ciuh-7 {
  list-style-type: none;
}
ul.lst-kix_ew1m0ki6ciuh-6 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-8.start {
  counter-reset: lst-ctn-kix_qgko7dyni29b-8 0;
}
ol.lst-kix_qgko7dyni29b-3.start {
  counter-reset: lst-ctn-kix_qgko7dyni29b-3 0;
}
.lst-kix_gv030h50s9au-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_gv030h50s9au-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_gv030h50s9au-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_gv030h50s9au-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_gv030h50s9au-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_qgko7dyni29b-7 > li {
  counter-increment: lst-ctn-kix_qgko7dyni29b-7;
}
.lst-kix_gv030h50s9au-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_s1aqslmosxnn-8 {
  list-style-type: none;
}
ul.lst-kix_s1aqslmosxnn-7 {
  list-style-type: none;
}
ul.lst-kix_s1aqslmosxnn-4 {
  list-style-type: none;
}
ul.lst-kix_s1aqslmosxnn-3 {
  list-style-type: none;
}
ul.lst-kix_s1aqslmosxnn-6 {
  list-style-type: none;
}
ul.lst-kix_s1aqslmosxnn-5 {
  list-style-type: none;
}
.lst-kix_gv030h50s9au-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_gv030h50s9au-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_s1aqslmosxnn-0 {
  list-style-type: none;
}
ul.lst-kix_s1aqslmosxnn-2 {
  list-style-type: none;
}
ul.lst-kix_s1aqslmosxnn-1 {
  list-style-type: none;
}
.lst-kix_gv030h50s9au-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_yxt01h54702q-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_lg09ab1ht2u7-1 {
  list-style-type: none;
}
.lst-kix_yxwgacwmpo4p-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_lg09ab1ht2u7-2 {
  list-style-type: none;
}
ul.lst-kix_lg09ab1ht2u7-3 {
  list-style-type: none;
}
ul.lst-kix_lg09ab1ht2u7-4 {
  list-style-type: none;
}
.lst-kix_yxwgacwmpo4p-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_lg09ab1ht2u7-0 {
  list-style-type: none;
}
.lst-kix_qgko7dyni29b-0 > li {
  counter-increment: lst-ctn-kix_qgko7dyni29b-0;
}
.lst-kix_qgko7dyni29b-3 > li {
  counter-increment: lst-ctn-kix_qgko7dyni29b-3;
}
.lst-kix_ew1m0ki6ciuh-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_ew1m0ki6ciuh-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_yxwgacwmpo4p-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_yxt01h54702q-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_yxwgacwmpo4p-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_yxt01h54702q-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_yxwgacwmpo4p-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_yxwgacwmpo4p-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_yxt01h54702q-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_qgko7dyni29b-4 > li {
  counter-increment: lst-ctn-kix_qgko7dyni29b-4;
}
.lst-kix_lg09ab1ht2u7-4 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_qgko7dyni29b-1.start {
  counter-reset: lst-ctn-kix_qgko7dyni29b-1 0;
}
ul.lst-kix_kk1u7uti64yl-6 {
  list-style-type: none;
}
ul.lst-kix_kk1u7uti64yl-5 {
  list-style-type: none;
}
ul.lst-kix_kk1u7uti64yl-8 {
  list-style-type: none;
}
ul.lst-kix_kk1u7uti64yl-7 {
  list-style-type: none;
}
.lst-kix_qgko7dyni29b-2 > li:before {
  content: "" counter(lst-ctn-kix_qgko7dyni29b-2, lower-roman) ". ";
}
ul.lst-kix_kk1u7uti64yl-2 {
  list-style-type: none;
}
.lst-kix_ew1m0ki6ciuh-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_yxt01h54702q-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_kk1u7uti64yl-1 {
  list-style-type: none;
}
ul.lst-kix_kk1u7uti64yl-4 {
  list-style-type: none;
}
.lst-kix_lg09ab1ht2u7-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_kk1u7uti64yl-3 {
  list-style-type: none;
}
.lst-kix_qgko7dyni29b-4 > li:before {
  content: "" counter(lst-ctn-kix_qgko7dyni29b-4, lower-latin) ". ";
}
.lst-kix_ew1m0ki6ciuh-3 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_qgko7dyni29b-4.start {
  counter-reset: lst-ctn-kix_qgko7dyni29b-4 0;
}
ul.lst-kix_kk1u7uti64yl-0 {
  list-style-type: none;
}
.lst-kix_lg09ab1ht2u7-0 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_qgko7dyni29b-2.start {
  counter-reset: lst-ctn-kix_qgko7dyni29b-2 0;
}
.lst-kix_j9qq9gseka2-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_bm4yuh5tb4fj-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_qgko7dyni29b-5 > li {
  counter-increment: lst-ctn-kix_qgko7dyni29b-5;
}
.lst-kix_s1aqslmosxnn-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_s1aqslmosxnn-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_s1aqslmosxnn-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_qgko7dyni29b-0 > li:before {
  content: "" counter(lst-ctn-kix_qgko7dyni29b-0, decimal) ". ";
}
.lst-kix_bm4yuh5tb4fj-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_lg09ab1ht2u7-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_s1aqslmosxnn-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_lvvaij1on1qb-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_lvvaij1on1qb-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_bm4yuh5tb4fj-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_lg09ab1ht2u7-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_lvvaij1on1qb-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_lvvaij1on1qb-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_kk1u7uti64yl-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_bm4yuh5tb4fj-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_j9qq9gseka2-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_kk1u7uti64yl-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_j9qq9gseka2-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_kk1u7uti64yl-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_j9qq9gseka2-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_kk1u7uti64yl-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_s1aqslmosxnn-2 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_qgko7dyni29b-0.start {
  counter-reset: lst-ctn-kix_qgko7dyni29b-0 0;
}
.lst-kix_qgko7dyni29b-8 > li {
  counter-increment: lst-ctn-kix_qgko7dyni29b-8;
}
.lst-kix_qgko7dyni29b-8 > li:before {
  content: "" counter(lst-ctn-kix_qgko7dyni29b-8, lower-roman) ". ";
}
ul.lst-kix_j9qq9gseka2-2 {
  list-style-type: none;
}
.lst-kix_qgko7dyni29b-6 > li:before {
  content: "" counter(lst-ctn-kix_qgko7dyni29b-6, decimal) ". ";
}
ul.lst-kix_j9qq9gseka2-3 {
  list-style-type: none;
}
ul.lst-kix_j9qq9gseka2-0 {
  list-style-type: none;
}
.lst-kix_qgko7dyni29b-7 > li:before {
  content: "" counter(lst-ctn-kix_qgko7dyni29b-7, lower-latin) ". ";
}
ul.lst-kix_j9qq9gseka2-1 {
  list-style-type: none;
}
ul.lst-kix_j9qq9gseka2-6 {
  list-style-type: none;
}
ul.lst-kix_j9qq9gseka2-7 {
  list-style-type: none;
}
ul.lst-kix_j9qq9gseka2-4 {
  list-style-type: none;
}
ul.lst-kix_j9qq9gseka2-5 {
  list-style-type: none;
}
ul.lst-kix_j9qq9gseka2-8 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-6.start {
  counter-reset: lst-ctn-kix_qgko7dyni29b-6 0;
}
ol.lst-kix_qgko7dyni29b-5 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-6 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-7 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-8 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-1 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-2 {
  list-style-type: none;
}
ul.lst-kix_yxt01h54702q-8 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-3 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-4 {
  list-style-type: none;
}
ul.lst-kix_gv030h50s9au-4 {
  list-style-type: none;
}
ul.lst-kix_gv030h50s9au-5 {
  list-style-type: none;
}
ul.lst-kix_gv030h50s9au-2 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-0 {
  list-style-type: none;
}
ul.lst-kix_gv030h50s9au-3 {
  list-style-type: none;
}
ol.lst-kix_qgko7dyni29b-5.start {
  counter-reset: lst-ctn-kix_qgko7dyni29b-5 0;
}
ul.lst-kix_gv030h50s9au-8 {
  list-style-type: none;
}
ul.lst-kix_gv030h50s9au-6 {
  list-style-type: none;
}
ul.lst-kix_gv030h50s9au-7 {
  list-style-type: none;
}
.lst-kix_qgko7dyni29b-6 > li {
  counter-increment: lst-ctn-kix_qgko7dyni29b-6;
}
ul.lst-kix_yxt01h54702q-5 {
  list-style-type: none;
}
ul.lst-kix_yxt01h54702q-4 {
  list-style-type: none;
}
ul.lst-kix_yxt01h54702q-7 {
  list-style-type: none;
}
ul.lst-kix_yxt01h54702q-6 {
  list-style-type: none;
}
ul.lst-kix_yxt01h54702q-1 {
  list-style-type: none;
}
ul.lst-kix_yxt01h54702q-0 {
  list-style-type: none;
}
ul.lst-kix_yxt01h54702q-3 {
  list-style-type: none;
}
ul.lst-kix_yxt01h54702q-2 {
  list-style-type: none;
}
.lst-kix_ew1m0ki6ciuh-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_qgko7dyni29b-2 > li {
  counter-increment: lst-ctn-kix_qgko7dyni29b-2;
}
.lst-kix_ew1m0ki6ciuh-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_ew1m0ki6ciuh-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_yxt01h54702q-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_lg09ab1ht2u7-5 {
  list-style-type: none;
}
ul.lst-kix_gv030h50s9au-0 {
  list-style-type: none;
}
ul.lst-kix_lg09ab1ht2u7-6 {
  list-style-type: none;
}
ul.lst-kix_gv030h50s9au-1 {
  list-style-type: none;
}
ul.lst-kix_lg09ab1ht2u7-7 {
  list-style-type: none;
}
.lst-kix_yxwgacwmpo4p-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_yxwgacwmpo4p-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_lg09ab1ht2u7-8 {
  list-style-type: none;
}
.lst-kix_yxt01h54702q-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_yxt01h54702q-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_yxwgacwmpo4p-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_yxwgacwmpo4p-8 {
  list-style-type: none;
}
ul.lst-kix_yxwgacwmpo4p-7 {
  list-style-type: none;
}
.lst-kix_qgko7dyni29b-1 > li {
  counter-increment: lst-ctn-kix_qgko7dyni29b-1;
}
ul.lst-kix_yxwgacwmpo4p-0 {
  list-style-type: none;
}
.lst-kix_lvvaij1on1qb-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_yxwgacwmpo4p-2 {
  list-style-type: none;
}
ul.lst-kix_yxwgacwmpo4p-1 {
  list-style-type: none;
}
ul.lst-kix_yxwgacwmpo4p-4 {
  list-style-type: none;
}
ul.lst-kix_yxwgacwmpo4p-3 {
  list-style-type: none;
}
ul.lst-kix_yxwgacwmpo4p-6 {
  list-style-type: none;
}
ul.lst-kix_yxwgacwmpo4p-5 {
  list-style-type: none;
}
.lst-kix_yxt01h54702q-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_lg09ab1ht2u7-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_qgko7dyni29b-3 > li:before {
  content: "" counter(lst-ctn-kix_qgko7dyni29b-3, decimal) ". ";
}
.lst-kix_ew1m0ki6ciuh-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_lg09ab1ht2u7-1 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_qgko7dyni29b-7.start {
  counter-reset: lst-ctn-kix_qgko7dyni29b-7 0;
}
.lst-kix_qgko7dyni29b-5 > li:before {
  content: "" counter(lst-ctn-kix_qgko7dyni29b-5, lower-roman) ". ";
}
.lst-kix_ew1m0ki6ciuh-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_bm4yuh5tb4fj-0 {
  list-style-type: none;
}
.lst-kix_s1aqslmosxnn-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_s1aqslmosxnn-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_bm4yuh5tb4fj-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_kk1u7uti64yl-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_bm4yuh5tb4fj-6 {
  list-style-type: none;
}
ul.lst-kix_bm4yuh5tb4fj-5 {
  list-style-type: none;
}
ul.lst-kix_bm4yuh5tb4fj-8 {
  list-style-type: none;
}
ul.lst-kix_bm4yuh5tb4fj-7 {
  list-style-type: none;
}
.lst-kix_s1aqslmosxnn-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_s1aqslmosxnn-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_bm4yuh5tb4fj-2 {
  list-style-type: none;
}
ul.lst-kix_bm4yuh5tb4fj-1 {
  list-style-type: none;
}
.lst-kix_j9qq9gseka2-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_bm4yuh5tb4fj-4 {
  list-style-type: none;
}
ul.lst-kix_bm4yuh5tb4fj-3 {
  list-style-type: none;
}
.lst-kix_qgko7dyni29b-1 > li:before {
  content: "" counter(lst-ctn-kix_qgko7dyni29b-1, lower-latin) ". ";
}
.lst-kix_bm4yuh5tb4fj-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_lvvaij1on1qb-8 {
  list-style-type: none;
}
ul.lst-kix_lvvaij1on1qb-7 {
  list-style-type: none;
}
ul.lst-kix_lvvaij1on1qb-6 {
  list-style-type: none;
}
.lst-kix_lg09ab1ht2u7-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_lvvaij1on1qb-5 {
  list-style-type: none;
}
.lst-kix_bm4yuh5tb4fj-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_lvvaij1on1qb-4 {
  list-style-type: none;
}
ul.lst-kix_lvvaij1on1qb-3 {
  list-style-type: none;
}
ul.lst-kix_lvvaij1on1qb-2 {
  list-style-type: none;
}
ul.lst-kix_lvvaij1on1qb-1 {
  list-style-type: none;
}
.lst-kix_kk1u7uti64yl-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_bm4yuh5tb4fj-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_lvvaij1on1qb-0 {
  list-style-type: none;
}
.lst-kix_lg09ab1ht2u7-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_lvvaij1on1qb-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_lvvaij1on1qb-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_lvvaij1on1qb-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_lvvaij1on1qb-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_j9qq9gseka2-8 > li:before {
  content: "\0025a0   ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_kk1u7uti64yl-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_j9qq9gseka2-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_bm4yuh5tb4fj-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_kk1u7uti64yl-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_j9qq9gseka2-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_j9qq9gseka2-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_kk1u7uti64yl-2 > li:before {
  content: "\0025a0   ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c6 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #1e2f97;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #1e2f97;
  vertical-align: top;
  border-right-color: #1e2f97;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 500.5pt;
  border-top-color: #1e2f97;
  border-bottom-style: solid;
}
.c5 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #1e2f97;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #1e2f97;
  vertical-align: top;
  border-right-color: #1e2f97;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 49.5pt;
  border-top-color: #1e2f97;
  border-bottom-style: solid;
}
.c12 {
  color: #404040;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c2 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c10 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c4 {
  padding-top: 0pt;
  color: #1e2f97;
  padding-bottom: 0pt;
  line-height: 1.15;
  font-size: 14pt;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c21 {
  padding-top: 10pt;
  padding-bottom: 10pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c9 {
  margin-left: 72pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c16 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}
.c0 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c20 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c15 {
  color: inherit;
  text-decoration: inherit;
}
.c7 {
  padding: 0;
  margin: 0;
}
.c1 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c14 {
  margin-left: 72pt;
}
.c18 {
  color: #004494;
}
.c17 {
  color: #222222;
}
.c11 {
  height: 11pt;
}
.c19 {
  color: #404040;
}
.c13 {
  height: 0pt;
}
.c8 {
  margin-left: 36pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>