<template>
  <section class="quality-area pt-120 pb-90 fix">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 col-md-9">
          <div class="kquality mb-30">
            <div class="kquality-icon">
              <i class="flaticon-ecommerce"></i>
            </div>
            <div class="kquality-text fix">
              <h3 class="mb-20 kquality-text-title">
                We Deliver the Best Innovation
              </h3>
              <p>
                NovaXon has always been future oriented and will continue to be.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-3">
          <div class="text-lg-end mb-30">
            <div class="kquality-img">
              <img
                src="../../assets/img/trust/cta-img.jpg"
                class="img-fluid"
                alt="cta-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InnovationArea",
};
</script>