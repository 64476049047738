<template>
  <section class="project-details-area pt-120 pb-70">
    <div class="container">
      <!--<div class="row">
        <div class="col-xxl-12">
          <div class="project-big-thumb">
            <img
              src="../../assets/img/portfolio/portfolio-big-img.jpg"
              alt=""
            />
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12">
          <div class="p-details-content mb-40">
            <h3>Others</h3>
            <p>
              There is no limit to the possibilities including the followings:
            </p>

            <p>Such collaboration will enable many innovative features like:</p>
            <li>• Home nursing with tablet (15 years experience)</li>
            <li>• Personalized medicine production</li>
            <li>• Crops Field Research using Offline possibility</li>
            <p>Feel free to contact to discuss your use cases</p>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
          <div class="platform-big-thumb">
            <img src="../../assets/img/usecases/Manufacturing.jpeg" alt="" />
          </div>
          <!--<div class="sidebar-wrap mb-40">
                           <div class="sidebar-right">
                              <div class="sidebar-single">
                                    <label>Clients:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Category:</label>
                                    <span>Design, Development</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Value:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Date:</label>
                                    <span>20 June, 2022</span>
                              </div>
                           </div>
                        </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "UseCasesDetailsOthers",
};
</script>