<template>
  <section class="project-details-area pt-120 pb-70">
    <div class="container">
      <!--<div class="row">
        <div class="col-xxl-12">
          <div class="project-big-thumb">
            <img
              src="../../assets/img/portfolio/portfolio-big-img.jpg"
              alt=""
            />
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12">
          <div class="p-details-content mb-40">
            <h3>Compliance</h3>
            <p>
              For 25 years, NovaXon has been committed to adhere to the various
              regulations to support GxP processes like CFR21 part 11.
            </p>

            <p>
              This translates to some specific features on the XClin platform
              like the possibility to see who did what when on the data.
            </p>
            <p>
              Furthermore its offers many benefits from a usability point of
              view
            </p>
            <p>
              NovaXon and the XClin platforms have been audited twice by its
              customers for adherence to such regulations.
            </p>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
          <div class="platform-big-thumb">
            <img src="../../assets/img/platform/audit.png" alt="" />
          </div>
          <!--<div class="sidebar-wrap mb-40">
                           <div class="sidebar-right">
                              <div class="sidebar-single">
                                    <label>Clients:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Category:</label>
                                    <span>Design, Development</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Value:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Date:</label>
                                    <span>20 June, 2022</span>
                              </div>
                           </div>
                        </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PlatformDetailsAudit",
};
</script>