<template>
  <section class="project-details-area pt-120 pb-70">
    <div class="container">
      <!--<div class="row">
        <div class="col-xxl-12">
          <div class="project-big-thumb">
            <img
              src="../../assets/img/portfolio/portfolio-big-img.jpg"
              alt=""
            />
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12">
          <div class="p-details-content mb-40">
            <h3>Decentralized Clinical Trial</h3>
            <p>
              With its 22 years experience in the collection of clinical trial
              data, NovaXon is proud to offer a very flexible platform to
              support innovative Decentralized Clinical Trials
            </p>
            <p>
              Working on multiple devices like Apple IOS or Android, the
              platform can be used by the many actors including the patients.
            </p>

            <p>
              Due to the extreme flexibility of the eForms, processes like
              eConsent, teleconsultation can be implemented in the simple
              platform without the need to work with multiple disparate solution
              making the training and support a lot easy.
            </p>
            <p>
              With the possibility to connect any type of Digital Health
              Technologies, novel endpoints can also be easily implemented.
            </p>
            <p>Feel free to ask for a demonstration</p>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
          <div class="platform-big-thumb">
            <img src="../../assets/img/usecases/DCT.jpeg" alt="" />
          </div>
          <!--<div class="sidebar-wrap mb-40">
                           <div class="sidebar-right">
                              <div class="sidebar-single">
                                    <label>Clients:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Category:</label>
                                    <span>Design, Development</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Value:</label>
                                    <span>Jessica Brown</span>
                              </div>
                              <div class="sidebar-single">
                                    <label>Date:</label>
                                    <span>20 June, 2022</span>
                              </div>
                           </div>
                        </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "UseCasesDetailsDCT",
};
</script>